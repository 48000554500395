import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { Datepicker, useTimeAgo } from '@tradetrax/web-common';
import { PrimaryButton } from '@tradetrax/web-common/lib/Button';
import { Impact } from '@tradetrax/web-common/lib/Task/Impact';
import WarningCriticalMsg, {
  CriticalDay,
  criticalProposedDateClass,
  showWarningCriticalMsg,
} from '@tradetrax/web-common/lib/CriticalPath/WarningCriticalMsg';
import {
  datepickerFormat,
  mongoToTrx,
  getMinEndDate,
  isDifferentYear,
  mongoToTextYearComparison,
  plural,
} from '@tradetrax/web-common/lib/utils';

export function UpdateRequestModal({ task, accept, cancel }) {
  const { changeRequest, durationDays } = task.toObject();
  const { proposedFinishDate, jobDelay, hourRange, createdByName } = changeRequest.toObject();
  const isJobDelayed = (jobDelay || 0) > 0;
  const isTaskDelayed = (task.get('daysBehind') || 0) > 0;
  const impactText = jobDelay === 0 ? 'Cycle Time Impact' : isJobDelayed ? 'Increase Cycle Time' : 'Reduce Cycle Time';
  const isSystemUR = createdByName === 'TradeTrax';
  const proposedReadOnly = isSystemUR && durationDays === 1;
  const [proposedDate, setProposedDate] = React.useState(proposedFinishDate);
  const [isChecked, setIsChecked] = React.useState(task.getIn(['changeRequest', 'reasons'])?.includes('weather'));

  return (
    <StyledModal show={true} onHide={() => cancel()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          Update Request
          <h6 className="font-weight-bold">{`Task: ${task.get('name')}`}</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Impact>
          <li>
            <label>Proposed {proposedReadOnly ? 'Start' : 'Finish'}</label>
            <ProposedDateSelect
              readOnly={proposedReadOnly}
              task={task}
              proposedDate={proposedDate}
              setProposedDate={setProposedDate}
            />
          </li>
          <li>
            <label>Expected {proposedReadOnly ? 'Start' : 'Finish'}</label>
            <span className={cn({ 'text-danger': isTaskDelayed })}>
              {mongoToTextYearComparison(task.get('expectedFinishDate'))}
            </span>
          </li>
          <li className={cn({ 'd-none': !hourRange })}>
            <label className="font-weight-normal">Hour Range</label>
            <span className="text-gray-400">{hourRange}</span>
          </li>
          <li
            className={cn({
              'text-danger': isJobDelayed,
              'text-success': !isJobDelayed && jobDelay !== 0,
            })}
          >
            <label>{impactText}</label>
            <span className="pr-2">{plural.day(Math.abs(jobDelay))}</span>
          </li>
          <li>
            <span className="font-weight-bold">Due to Weather</span>
            <input
              style={{ width: '18px', height: '18px' }}
              type="checkbox"
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
            />
          </li>
        </Impact>
        <div className="font-size-14  d-flex flex-row mb-3">
          <span className="text-gray-400 mr-1">Update request by: </span>
          <span className="text-dark">{changeRequest.get('createdByName')}</span>
          <span className="text-gray-400 mr-3 ml-auto"> {useTimeAgo(changeRequest.get('createdOn'))}</span>
        </div>
        {isJobDelayed && (
          <span className="font-size-14 mt-3 text-gray-400">
            If approved, this Update Request <strong>will delay the finish date for the Job</strong>. Please
            double-check before sending it to the Builder for approval.
          </span>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex flex-row mt-4">
        <PrimaryButton variant="secondary" onClick={() => accept({ isDeclined: true })} className="ml-auto mr-3">
          Decline
        </PrimaryButton>
        <PrimaryButton onClick={() => accept({ proposedDate, reasonsWeather: isChecked })}>
          Send to Builder
        </PrimaryButton>
      </Modal.Footer>
    </StyledModal>
  );
}

const ProposedDateSelect = ({ task, proposedDate, setProposedDate, readOnly }) => {
  const selectedDate = datepickerFormat(mongoToTrx(proposedDate));
  const minEndDate = getMinEndDate(task.get('startDate'));
  const [isYearDisplayed, setIsYearDisplayed] = React.useState(isDifferentYear(selectedDate));
  const expectedFinishDate = task.get('expectedFinishDate');
  const expectedDate = expectedFinishDate ? datepickerFormat(mongoToTrx(expectedFinishDate)) : '';
  const lateDate = task.get('lateEndDate') || undefined;
  const criticalDate = new Date(lateDate);

  if (readOnly) {
    return <span>{mongoToTextYearComparison(selectedDate)}</span>;
  }

  const isDateBlocked = date => {
    if (!date || !expectedFinishDate) return false;

    const dateObj = date instanceof Date ? date : new Date(date);
    const formattedDate = dateObj.toISOString().split('T')[0];
    const formattedExpectedDate = new Date(expectedFinishDate).toISOString().split('T')[0];

    return formattedDate === formattedExpectedDate;
  };

  return (
    <div style={{ width: '130px' }}>
      <Datepicker
        name="propFinishDate"
        selected={selectedDate}
        minDate={minEndDate}
        dateFormat={`MMM dd${isYearDisplayed ? ', yyyy' : ''}`}
        onChange={date => {
          if (!isDateBlocked(date)) {
            setIsYearDisplayed(isDifferentYear(date));
            setProposedDate(date);
          }
        }}
        onFocus={() => setIsYearDisplayed(true)}
        onBlur={({ target }) => setIsYearDisplayed(isDifferentYear(Date.parse(target.value)))}
        dayClassName={date => criticalProposedDateClass(date, selectedDate, expectedDate, criticalDate)}
        renderDayContents={(day, date) => CriticalDay(day, date, criticalDate, selectedDate)}
        calendarClassName="react-datepicker__critical"
      >
        <WarningCriticalMsg showMsg={showWarningCriticalMsg(selectedDate, criticalDate)} />
      </Datepicker>
    </div>
  );
};

const StyledModal = styled(Modal)`
  .modal-dialog.modal-md {
    max-width: 600px;
  }
`;
