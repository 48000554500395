import { fromJS, Map, List } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { markAsSideEffect, markAsSync, TaskBulkAssignmentModal, CustomDialog } from '@tradetrax/web-common';
import { TaskTypeBulkAssignmentModal } from '@tradetrax/web-common/lib/OuttakeIntake/TaskTypeBulkAssignmentModal';
import { TASK_TYPE } from '@tradetrax/web-common/lib/OuttakeIntake/TasksBySelect';
import { FILTER_NAME_MAP, INSTALLER_TAB, MINIMUM_BATCH_SIZE, USER_TYPE } from './IntakeController';
import { subsService } from 'services';

const getArticle = tab => (tab === 'installer' ? 'an' : 'a');

export function readCommunity(communityId) {
  return subsService
    .readCommunity({}, { params: { communityId } })
    .then(community => state => state.set('community', fromJS(community)))
    .catch(error => {
      let hasPermission = true;
      if (error.httpCode === 404) hasPermission = false;
      return state => state.set('hasPermission', hasPermission);
    });
}

markAsSync(setTab);
export function setTab(state, tab) {
  const isFiltering = this.filtersState.getIn([FILTER_NAME_MAP[tab], 'isFiltering']);
  const updatedState = state.set('tab', tab);
  if (isFiltering) {
    setTimeout(() => this.loaderRef.current?.resetLoadMoreRowsCache(true), 1);
    return updatedState.update('allTasks', allTasks =>
      allTasks
        .set('totalCount', 10)
        .set('maxCount', 10)
        .set('tasks', List())
    );
  }
  return updatedState;
}

markAsSync(setTasksBy);
export function setTasksBy(state, tasksBy) {
  if (state.get('tasksBy') === tasksBy) return state;
  return state
    .set('tasksBy', tasksBy)
    .update('allTasks', allTasks =>
      allTasks
        .set('totalCount', 10)
        .set('maxCount', 10)
        .set('tasks', List())
    )
    .update('tasksByType', tasksByType =>
      tasksByType
        .set('totalCount', 10)
        .set('maxCount', 10)
        .set('tasks', List())
    );
}

markAsSync(setFilter);
export function setFilter(state, filter) {
  return state.set('filter', filter);
}

export function readCommunityTasks({ communityId, startIndex, stopIndex }) {
  const tab = this.state.get('tab');
  const filter = this.getFilter(tab).toJS();
  const query = getQueryParam({ start_index: startIndex, stop_index: stopIndex }, tab, filter);
  return subsService.readCommunityTasks({}, { params: { communityId }, query }).then(data => {
    const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;
    return state =>
      state.update('allTasks', allTasks =>
        allTasks
          .set('totalCount', totalCount)
          .set('maxCount', stopIndex + MINIMUM_BATCH_SIZE + 1)
          .update('tasks', tasks => tasks.splice(startIndex, stopIndex - startIndex + 1, ...fromJS(data).toArray()))
      );
  });
}

export function readCommunityTasksByType({ startIndex, stopIndex }) {
  const communityId = this.state.getIn(['community', '_id']);
  return subsService
    .readCommunityTasksByType(
      {},
      { params: { communityId }, query: { start_index: startIndex, stop_index: stopIndex } }
    )
    .then(data => {
      const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;
      return state =>
        state.update('tasksByType', tasksByType =>
          tasksByType
            .update('tasks', tasks => tasks.splice(startIndex, stopIndex - startIndex + 1, ...fromJS(data).toArray()))
            .set('totalCount', totalCount)
            .set('maxCount', stopIndex + MINIMUM_BATCH_SIZE + 1)
        );
    });
}

markAsSync(assignUserToTask);
export function assignUserToTask(state, { assignee, rowData, tab }) {
  const { task, job } = rowData.toObject();
  const filter = this.getFilter(tab);
  const jobId = job.get('id');
  const taskId = task.get('id');
  const index = state.getIn(['allTasks', 'tasks']).indexOf(rowData);
  const userType = USER_TYPE[tab];
  const assigneeStatus = assignee ? assignee.get('status') : 'active';
  const assigneeId = assignee ? assignee.get('_id') : null;
  const assigneeName = assignee ? `${assignee.get('firstName')} ${assignee.get('lastName')}` : null;
  const payload = {
    [userType.payloadField]: assigneeId,
  };
  if (tab === 'installer') payload.installerName = assigneeName;

  subsService.updateTask(payload, { params: { jobId, taskId } }).catch(() => {
    this.controller.dispatch([state => state.setIn(['allTasks', 'tasks', index], rowData)]);
    this.addAlert(
      `There was a problem assigning ${getArticle(tab)} 
      ${userType.label} to this Task. Please try again.`,
      'danger'
    );
  });

  const selectedTasksIds = state.getIn(['selectedTasks', tab, 'taskIds']);
  const assigneeIndex = selectedTasksIds.findIndex(taskId => taskId === rowData.get('id'));
  return state
    .updateIn(['allTasks', 'tasks', index, 'task'], task =>
      task.update(userType.dataField, user => {
        const assignee = user || Map();
        return assignee
          .set('status', assigneeStatus)
          .set('name', assigneeName)
          .set('_id', assigneeId);
      })
    )
    .update('allTasks', allTasks => {
      let totalCount = allTasks.get('totalCount');
      return allTasks
        .update('tasks', tasks => {
          const filterAssignees = filter.get('assigneeUserIds');
          if (filterAssignees.size === 0) return tasks;
          const index = tasks.findIndex(a => a.get('id') === rowData.get('id'));
          const assignee = tasks.getIn([index, 'task', userType.dataField]);
          const taskMatchingFilter = filterAssignees.filter(assigneeId => {
            return assignee?.get('_id') === assigneeId;
          });

          if (taskMatchingFilter.size) return tasks;
          totalCount--;
          return tasks.splice(index, 1);
        })
        .set('totalCount', totalCount);
    })
    .updateIn(['selectedTasks', tab, 'currentAssignees', assigneeIndex], currentUserId => {
      if (assigneeIndex >= 0) return assigneeId;
      else return currentUserId;
    });
}

markAsSync(toggleSelectAll);
export function toggleSelectAll(state, checked) {
  const tab = state.get('tab');
  const updatedState = state.updateIn(['selectedTasks', tab], selectedTasks =>
    selectedTasks
      .set('currentAssignees', List())
      .set('selectAll', checked)
      .set('taskIds', List())
  );
  if (checked) return updatedState;

  return updatedState.updateIn(['selectedTasks', tab], selectedTasks => {
    return selectedTasks.set('notIncludeTaskIds', List());
  });
}

markAsSync(onSelectCheckbox);
export function onSelectCheckbox(state, { checked, task, tab }) {
  const userType = USER_TYPE[tab].dataField;
  const taskId = task.get('id');
  const taskAssigneeId = task.getIn(['task', userType, '_id']) || null;
  const isSelectAll = state.getIn(['selectedTasks', tab, 'selectAll']);

  if (isSelectAll) return addRemoveFromExcluded(state, tab, taskId, checked);

  if (checked)
    return state.updateIn(['selectedTasks', tab], selectedtasks =>
      selectedtasks
        .update('taskIds', ids => ids.push(taskId))
        .update('currentAssignees', currentAssignees => currentAssignees.push(taskAssigneeId))
    );
  else {
    const index = state.getIn(['selectedTasks', tab, 'taskIds']).indexOf(taskId);
    if (index >= 0) {
      return state.updateIn(['selectedTasks', tab], selectedTasks =>
        selectedTasks
          .update('taskIds', ids => ids.remove(index))
          .update('currentAssignees', currentAssignees => {
            const index = currentAssignees.indexOf(taskAssigneeId);
            return currentAssignees.splice(index, 1);
          })
      );
    }
  }
}

markAsSync(toggleCheckboxByType);
export function toggleCheckboxByType(state, { checked, task }) {
  const tab = state.get('tab');
  const taskName = task.get('name');
  const gtlTaskId = task.get('gtlTaskId');

  if (checked)
    return state.updateIn(['selectedTasks', tab], selectedTasks =>
      selectedTasks
        .update('taskNames', taskNames => taskNames.push(taskName))
        .update('gtlTaskIds', gtlTaskIds => gtlTaskIds.push(gtlTaskId))
    );

  const index = state.getIn(['selectedTasks', tab, 'taskNames']).indexOf(taskName);
  const indexGtlId = state.getIn(['selectedTasks', tab, 'gtlTaskIds']).indexOf(gtlTaskId);

  return state.updateIn(['selectedTasks', tab], selectedTasks =>
    selectedTasks
      .update('taskNames', taskNames => taskNames.remove(index))
      .update('gtlTaskIds', gtlTaskIds => gtlTaskIds.remove(indexGtlId))
  );
}

function addRemoveFromExcluded(state, tab, taskId, checked) {
  if (checked) {
    const index = state.getIn(['selectedTasks', tab, 'notIncludeTaskIds']).indexOf(taskId);
    return state.updateIn(['selectedTasks', tab, 'notIncludeTaskIds'], taskIds => taskIds.splice(index, 1));
  } else return state.updateIn(['selectedTasks', tab, 'notIncludeTaskIds'], taskIds => taskIds.push(taskId));
}

markAsSideEffect(openBulkAssignmentModal);
export async function openBulkAssignmentModal(tasksBy) {
  if (tasksBy === TASK_TYPE) return openTasksByTypeModal.call(this);

  return openAllTasksModal.call(this);
}

markAsSideEffect(openAllTasksModal);
export async function openAllTasksModal() {
  const { tab, community } = this.state.toObject();
  const selectedTasks = this.state.getIn(['selectedTasks', tab]);
  const taskIds = selectedTasks.get('taskIds').toJS();
  const isSelectAll = selectedTasks.get('selectAll');

  const selectedAssignees = selectedTasks.get('currentAssignees');
  const isAnyNotAssigned = selectedAssignees.includes(null);
  const taskAssignees = [...new Set(selectedAssignees)].filter(item => item != null);
  const assigneeOptions =
    tab === 'installer' ? this.account.get('assigneesActive') : this.account.get('regularUsersActive');
  const { isAccept, assignee } = await this.modal.open(TaskBulkAssignmentModal, {
    context: this,
    community,
    isAnyNotAssigned,
    taskAssignees,
    tab,
    assigneeOptions,
    isSelectAll,
  });
  if (!isAccept) return;

  if (isSelectAll) return assignTasksByQuery.call(this, assignee);

  const tasks = this.state.getIn(['allTasks', 'tasks']);
  this.controller.dispatch([
    state =>
      state
        .updateIn(['selectedTasks', tab], selectedTasks =>
          selectedTasks
            .set('taskIds', List())
            .set('selectAll', false)
            .set('notIncludeTaskIds', List())
        )
        .updateIn(['allTasks', 'tasks'], tasks =>
          tasks.map(task => {
            if (taskIds.includes(task.get('id'))) {
              return task.updateIn(['task', USER_TYPE[tab].dataField], currentUser => {
                const [assigneeUserId, assigneeUserName] = assignee
                  ? [assignee.get('_id'), `${assignee.get('firstName')} ${assignee.get('lastName')}`]
                  : [null, null];
                if (!assigneeUserId) return null;
                const updatedUser = currentUser ? currentUser : Map();
                return updatedUser
                  .set('_id', assigneeUserId)
                  .set('name', assigneeUserName)
                  .set('status', 'active');
              });
            } else return task;
          })
        ),
  ]);

  const userId = assignee ? assignee.get('_id') : null;
  const assignTasksData = { taskIds };
  assignTasksData[USER_TYPE[tab].payloadField] = userId;

  return subsService
    .assignUsersToTasks(assignTasksData, { params: { communityId: community.get('_id') } })
    .then(() => {
      const assignUnassign = assignee ? 'assigned to' : 'removed from';
      this.alert.success({ message: `${USER_TYPE[tab].label} successfully ${assignUnassign} these Tasks.` });
      if (!assignee) {
        this.controller.dispatch([state => state.setIn(['selectedTasks', tab, 'currentAssignees'], List())]);
      }
    })
    .catch(() => {
      const assignUnassign = assignee ? 'assigning' : 'removing';
      this.alert.error({
        message: `There was a problem ${assignUnassign} ${getArticle(tab)} ${
          USER_TYPE[tab].label
        } to these Tasks. Please try again.`,
      });
      this.controller.dispatch([
        state =>
          state
            .setIn(['allTasks', 'tasks'], tasks)
            .updateIn(['selectedTasks', tab], selectedTasks =>
              selectedTasks.set('taskIds', fromJS(taskIds)).set('currentAssignees', selectedAssignees)
            ),
      ]);
    });
}

markAsSideEffect(openTasksByTypeModal);
export async function openTasksByTypeModal() {
  const { tab, community } = this.state.toObject();
  const communityId = community.get('_id');
  const taskNames = this.state.getIn(['selectedTasks', tab, 'taskNames']).toJS();
  const jobsPromise = subsService.readJobs({}, { query: { communityId, taskNames } });
  const assigneeOptions =
    tab === INSTALLER_TAB ? this.account.get('assigneesActive') : this.account.get('regularUsersActive');

  const hasExistingRule = !!this.state
    .getIn(['tasksByType', 'tasks'])
    .find(task => taskNames.some(name => name === task.get('name')))
    .getIn(['rule', USER_TYPE[tab].dataField]);

  const { isAccept, assignee, jobIds, createRule } = await this.modal.open(TaskTypeBulkAssignmentModal, {
    tab,
    community,
    jobsPromise,
    hasExistingRule,
    appState: this.appState,
    assigneeOptions,
  });

  if (!isAccept) return;

  if (tab === INSTALLER_TAB) return assignIntallerToTaskByType.call(this, { assignee, jobIds, createRule });
  else return assignUserToTaskByType.call(this, { assignee, jobIds, createRule });
}

function assignIntallerToTaskByType({ assignee, jobIds, createRule }) {
  const { tab, community, selectedTasks } = this.state.toObject();
  const installerId = assignee.get('_id');
  const communityId = community.get('_id');
  const currentTasks = this.state.getIn(['tasksByType', 'tasks']);
  const gtlTaskIds = selectedTasks.getIn([tab, 'gtlTaskIds']).toJS();
  const taskNames = selectedTasks.getIn([tab, 'taskNames']).toJS();

  const payload = { installerId, gtlTaskIds };
  if (jobIds.length) payload.jobIds = jobIds;

  this.controller.dispatch([
    state =>
      state.updateIn(['selectedTasks', tab], clearSelectedTasks).updateIn(['tasksByType', 'tasks'], tasks => {
        if (!createRule) return tasks;
        return tasks.map(task => {
          if (taskNames.some(name => name === task.get('name'))) {
            const hasRule = !!task.getIn(['rule', USER_TYPE[tab].dataField]);
            if (hasRule) return task.setIn(['rule', USER_TYPE[tab].dataField, 'name'], assignee.get('name'));
            else {
              const currentRule = task.get('rule') || Map();
              const newRule = currentRule.merge({
                [USER_TYPE[tab].dataField]: {
                  name: assignee.get('name'),
                },
              });
              return task.set('rule', newRule);
            }
          }
          return task;
        });
      }),
  ]);

  return subsService
    .assignUsersToTasks(payload, { params: { communityId } })
    .then(() => {
      if (createRule) {
        subsService.updateCommunityRules({ installerId, taskIds: gtlTaskIds }, { params: { communityId } });
        this.addAlert(
          `Installer successfully assigned to these Tasks and Rule created for ${taskNames.length} Task type group${
            taskNames.length > 1 ? 's' : ''
          }.`
        );
      } else this.addAlert(`Installer successfully assigned to these Tasks.`);
    })
    .catch(error => {
      this.controller.dispatch([
        state =>
          state
            .setIn(['tasksByType', 'tasks'], currentTasks)
            .setIn(['selectedTasks', tab, 'taskNames'], fromJS(taskNames)),
      ]);
      if (createRule)
        this.addAlert(
          `There was a problem assigning an Installer to these Tasks and creating the corresponding Rule for the Task type group(s). Please try again.`,
          'danger'
        );
      else this.addAlert(`There was a problem assigning an Installer to this Task. Please try again.`, 'danger');
    });
}

function assignUserToTaskByType({ assignee, jobIds, createRule }) {
  const { tab, community, selectedTasks } = this.state.toObject();
  const gtlTaskIds = selectedTasks.getIn([tab, 'gtlTaskIds']).toJS();
  const taskNames = selectedTasks.getIn([tab, 'taskNames']).toJS();
  const communityId = community.get('_id');
  const currentTasks = this.state.getIn(['tasksByType', 'tasks']);
  const payload = {
    gtlTaskIds,
    [USER_TYPE[tab].payloadField]: assignee.get('_id'),
  };
  if (jobIds.length) payload.jobIds = jobIds;

  this.controller.dispatch([
    state =>
      state.updateIn(['selectedTasks', tab], clearSelectedTasks).updateIn(['tasksByType', 'tasks'], tasks => {
        if (!createRule) return tasks;

        return tasks.map(task => {
          if (taskNames.some(name => name === task.get('name'))) {
            const hasRule = !!task.getIn(['rule', USER_TYPE[tab].dataField]);
            const ruleName = `${assignee.get('firstName')} ${assignee.get('lastName')}`;
            if (hasRule) return task.setIn(['rule', USER_TYPE[tab].dataField, 'name'], ruleName);
            else {
              const currentRule = task.get('rule') || Map();
              return task.set(
                'rule',
                currentRule.merge({
                  [USER_TYPE[tab].dataField]: {
                    name: ruleName,
                  },
                })
              );
            }
          }
          return task;
        });
      }),
  ]);

  return subsService
    .assignUsersToTasks(payload, { params: { communityId } })
    .then(() => {
      if (createRule) {
        subsService
          .updateCommunityRules(
            { [USER_TYPE[tab].payloadField]: assignee.get('_id'), taskIds: gtlTaskIds },
            { params: { communityId } }
          )
          .then(() => {
            this.alert.success({
              message: `${USER_TYPE[tab].label} successfully assigned to these Tasks and Rule created for ${
                taskNames.length
              } Task type group${taskNames.length > 1 ? 's' : ''}.`,
            });
          });
      } else {
        this.alert.success({
          message: `${USER_TYPE[tab].label} successfully assigned to these Tasks.`,
        });
      }
    })
    .catch(() => {
      this.controller.dispatch([
        state =>
          state
            .setIn(['tasksByType', 'tasks'], currentTasks)
            .setIn(['selectedTasks', tab, 'taskNames'], fromJS(taskNames)),
      ]);
      if (createRule)
        this.alert.error({
          message: `There was a problem assigning ${getArticle(tab)}  ${
            USER_TYPE[tab].label
          } to these Tasks and creating the corresponding Rule for the Task type group(s). Please try again.`,
        });
      else
        this.alert.error({
          message: `There was a problem assigning ${getArticle(tab)}  ${
            USER_TYPE[tab].label
          } to these Tasks. Please try again.`,
        });
    });
}

const clearSelectedTasks = selectedTasks =>
  selectedTasks
    .set('taskIds', List())
    .set('taskNames', List())
    .set('currentAssignees', List())
    .set('gtlTaskIds', List());

markAsSideEffect(assignTasksByQuery);
export function assignTasksByQuery(assignee) {
  const { tab } = this.state.toObject();
  const userId = assignee ? assignee.get('_id') : null;
  const filter = this.getFilter(tab);
  const communityId = this.state.getIn(['community', '_id']);
  const notIncludeTaskIds = this.state.getIn(['selectedTasks', tab, 'notIncludeTaskIds']);
  const prevTasks = this.state.getIn(['allTasks', 'tasks']);
  const body = {
    [USER_TYPE[tab].payloadField]: userId,
  };
  const query = {
    assigneeUserType: tab,
  };
  if (notIncludeTaskIds.size) query.notIncludeTaskIds = notIncludeTaskIds.toJS();
  if (filter.get('assigneeUserIds')?.size) {
    query.assigneeUserIds = filter.get('assigneeUserIds').toJS();
  }

  this.controller.dispatch([
    state =>
      state
        .updateIn(['selectedTasks', tab], selectedTasks =>
          selectedTasks
            .set('taskIds', List())
            .set('selectAll', false)
            .set('notIncludeTaskIds', List())
        )
        .updateIn(['allTasks', 'tasks'], tasks =>
          tasks.map(task => {
            if (notIncludeTaskIds.includes(task.get('id'))) return task;

            return task.updateIn(['task', USER_TYPE[tab].dataField], currentUser => {
              const [assigneeUserId, assigneeUserName] = assignee
                ? [assignee.get('_id'), `${assignee.get('firstName')} ${assignee.get('lastName')}`]
                : [null, null];
              if (!assigneeUserId) return null;
              const updatedUser = currentUser ? currentUser : Map();
              return updatedUser
                .set('_id', assigneeUserId)
                .set('name', assigneeUserName)
                .set('status', 'active');
            });
          })
        ),
  ]);

  const assignUnassign = userId ? 'assigned to' : 'removed from';
  return subsService
    .assignUsersToTasksByQuery(body, { params: { communityId }, query })
    .then(() => {
      this.alert.success({ message: `${USER_TYPE[tab].label} successfully ${assignUnassign} these Tasks.` });
    })
    .catch(error => {
      this.alert.error({
        message: `There was a problem ${assignUnassign} ${getArticle(tab)} ${
          USER_TYPE[tab].label
        } to these Tasks. Please try again.`,
      });
      this.controller.dispatch([state => state.setIn(['allTasks', 'tasks'], prevTasks)]);
    });
}

markAsSideEffect(removeRule);
export async function removeRule({ taskType, tab }) {
  const communityId = this.state.getIn(['community', '_id']);
  const index = this.state.getIn(['tasksByType', 'tasks']).indexOf(taskType);
  const taskIds = [taskType.get('gtlTaskId')];
  const ruleType = USER_TYPE[tab].dataField;

  const { isAccept } = await this.modal.open(CustomDialog, {
    title: (
      <>
        <FontAwesomeIcon icon="circle-exclamation" className="text-danger" />
        Removal Alert
      </>
    ),
    message:
      'By removing this Rule, this Task Type will not be assigned automatically in future Jobs. Do you confirm the removal?',
    titleAccept: 'Yes, Remove Rule',
    titleCancel: 'Cancel',
  });
  if (!isAccept) return;
  const path = ['tasksByType', 'tasks', index, 'rule', ruleType];
  const payload = { taskIds };
  const idField = USER_TYPE[tab].payloadField;
  if (ruleType === 'installer') payload.installerId = null;
  else payload[idField] = null;
  this.controller.dispatch([state => state.setIn(path, null)]);
  return subsService
    .updateCommunityRules(payload, { params: { communityId } })
    .then(() => this.addAlert('Task type Rule successfully removed.', 'success'))
    .catch(() => {
      this.addAlert('There was a problem removing this Task type Rule. Please try again.', 'danger');
      const currentRule = this.state.getIn(path);
      this.controller.dispatch([state => state.setIn(path, currentRule)]);
    });
}

markAsSync(invalidateFilter);
export function invalidateFilter(state) {
  setTimeout(() => this.loaderRef.current?.resetLoadMoreRowsCache(true), 1);
  return state.set('allTasks', fromJS({ tasks: [], totalCount: 10, maxCount: 10 }));
}

export function getQueryParam(query = {}, tab, filter) {
  if (!filter) return query;

  if (filter.assigneeUserIds?.length) {
    query.assigneeUserType = tab;
    query.assigneeUserIds = filter.assigneeUserIds;
  }

  return query;
}
